.news{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    overflow: hidden;

}

.newsItem{
    text-decoration: none;
    color: #3e3e3e;
    font-family: "Montserrat";
    padding: 5px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 250px;
    height: 300px;
    background-color: #f0f0f0;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    &:hover{
        box-shadow: 2px 2px 2px #3f3f3f;
    }
    > img {
        width: 90%;
        align-self: center;
        margin-top: 10px;
        max-width: 300px;
        height: 100px;
    }
    > p {
        margin: 20px 10px 0px 10px;
    }

}