.ktrv-header {
    border-bottom: 1px #006D78 solid;
    display: flex;
    position: relative;
    z-index: 3;
    background: #006E78;
    padding: 12px 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.ktrv-header__logo svg,
.ktrv-header__logo img {
    max-width: 100%;
}
.ktrv-header_dark {
    background: #4A4A4A;
}	
.ktrv-header_white {
    background: #fff;
}
.ktrv-header__menu {
    display: flex;
    flex-wrap: wrap;
    padding-top: 11px;
}
.ktrv-header__menu-item {
    margin-right: 20px;
    margin-top: 20px;
}
.ktrv-header__menu-item:last-child {
    margin-right: 0;
}
.ktrv-header__menu-link {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    margin: 0px 5px;
}
.ktrv-header_white .ktrv-header__menu-link {
    color: #006E78;
}