.backgroundMap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        // background: rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.75);
        width: 100vw;
        height: 100vh;
    }

    &> div:nth-child(2) > ymaps:nth-child(1) {
        display: none;
    }
}

.contentHomepage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 90px;
    font-family: "Montserrat";
    justify-content: center;
    // height: 85%;
    height: calc(100vh - 210px);

    > * {
        position: relative;
        z-index: 3;
    }

    img {
        width: 100px;
    }

    .OAO {
        margin-top: 50px;
        font-size: 22px;
        font-weight: 600;
        font-family: "Montserrat";
        color: #3e3e3e;
    }
}

.arz711 {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: max-content max-content;

    .blue {
        color: #006D78;
    }

    > p {
        margin: 0;
        font-size: 160px;
        font-weight: 700;
        letter-spacing: 10px;
        color: #006D78;
        // margin-top: -30px;
        line-height: 110px;
        margin-top: 10px;
    }

    .arz711-info {
        margin-top: 10px;

        > p {
            font-size: 32px;
            font-weight: bold;
            line-height: 32px;
            margin: 0;
        }

        > p:not(:last-child) {
            padding-bottom: 10px;
        }
    }
}

.button {
    background: #e3cd29;
    padding: 10px 55px;
    border-radius: 10px;
    margin-top: 25px;
    color: #3e3e3e;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 18px;
    text-decoration: none;
}

.bigKtrvLogo {
    z-index: 5;
    position: absolute;
    top: 0;
    height: 100vh;
    opacity: 10%;
    left: 10%;
}

.bigKTRVLogo2 {
    opacity: 0.2 !important;
}



.skyBlock {
    position: absolute;
    // bottom: 0;
    // right: 0;
    // bottom: -15%;
    // bottom: -5%;
    bottom: 0;
    // right: -5%;
    right: 0%;

    // .skyContent {
    //     // overflow: hidden;
    // }

    > * {
        position: relative;
        z-index: 3;
    }

    .clouds {
        position: absolute;
        left: 0;

        .cloud {
            position: relative;
            z-index: 5;
            height: 120px;
        }

        .cloud1 {
            margin-left: 25%;
            margin-top: -10%;
        }

        .cloud2 {
            margin-top: 35%;
            margin-left: -15%;
        }
    }
}

.skyCircle {
    height: 500px;
    width: 500px;
    // height: 500px;
    // height: 450px;
    // border-radius: 50%;
    border-radius: 50% 50% 0 50%;
    overflow: hidden;
    // width: 500px;

    img {
        height: 100%;
    }
}

.arzMiniInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
        // padding-top: 100px;
        // font-weight: 600;
        color: #006D78;
        margin-bottom: 15px;
        font-size: 22px;
        font-weight: 600;
        font-family: "Montserrat";
    }
}

.overflowAnimate {
    margin-bottom: 100px;
}
