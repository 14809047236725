.newsContent {
    > p {
        font-size: 32px;
        margin: 0;
        margin-top: 0px;
        font-weight: 700;
        font-family: "Montserrat";
    }

    > span {
        font-size: 14px;
    }

    .newsData {
        word-wrap: break-word;
    }
}

.blockName.back {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 15;
    position: relative;
    width: fit-content;
    
    > p {
        font-size: 16px;
        // padding: 10px 0;
        padding-right: 15px;
        margin: 0;
        cursor: pointer;

        &:hover {
            color: #116297;
        }
    }
}

.newsContentGrid {
    display: grid;
    // grid-template-columns: 1fr 2fr;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;

    .newsPhoto {
        width: 100%;
        height: 250px;
        background-size: cover !important;
        background-position: center !important;
        background-attachment: fixed !important;
        border-radius: 10px;
        margin-top: 15px;
        // margin-left: -25px;
        // img {
        //     width: 100%;
        //     border-radius: 10px;
        // }
    }
}