@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans";
    overflow-x: hidden;
}

.content {
    // height: calc(100vh - 100px);
    // height: fit-content;
    // overflow-x: hidden;
    width: 100vw;
    // position: relative;
    // height: max-content;
    // overflow-y: overlay;
}