.OAOLine {
    width: 100%;
    height: 50px;
    background: #0b616b;
    color: #f5f5f5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    font-family: "Montserrat";
}