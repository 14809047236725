.ktrv-footer {
    background: #006E78;
    padding: 1px 60px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.ktrv-footer_dark {
    background: #4A4A4A;
}
.ktrv-footer_white {
    background: #ffffff;
}
.ktrv-footer__logo {
    padding-top: 15px;
}
.ktrv-footer__logo a {
    text-decoration: none;
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.ktrv-footer__logo svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.ktrv-footer__logo span {
    display: inline-block;
    vertical-align: middle;
    margin-top: 15px;
}
.ktrv-footer .ktrv-header__menu {
    padding-top: 0;
}
.ktrv-footer .ktrv-header__menu-item {
    margin-top: 14px;
}
.ktrv-footer_white .ktrv-footer__logo a {
    color: #66676C;
}
.ktrv-footer_white .ktrv-header__menu-link {
    color: #66676C;	
}
@media (max-width: 991px) { 
    .ktrv-header, 
    .ktrv-footer {
        padding: 12px 20px;
    }
    .ktrv-header__menu-item {
        margin-top: 10px;
    }
}