.leftContent {
    width: 230px;
    // height: 100%;
    // height: calc(100vh - 200px);
    background: url("/assets/sky.jpg");
    background-size: cover;
    background-position: center;

    padding: 25px 70px;
    padding-left: 90px;
    position: relative;

    transition: .5s;

    height: calc(100vh - 236px);

    > * {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        left: 0;
    }

    .fixedPosition {
        // position: fixed;
    }
    
    &.active {
        width: 230px;
    }

    .closePodMenu {
        display: none;
    }
}

.arzBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Montserrat";

    .OAO {
        font-size: 10px;
        font-weight: 500;
    }

    .splitInfo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;

        > img {
            width: 80px;
        }
    
        > p {
            margin: 0;
            font-size: 70px;
            font-weight: 700;
            letter-spacing: 1px;
            color: #116297;
            line-height: 50px;
            // margin-top: 10px;
        }
    }

    .arz711 {
        display: flex;
        flex-direction: column;

        .arz711-info {
            margin-top: 15px;
            
            > p {
                font-size: 21px;
                line-height: 15px;
            }
        } 
    }
}

.contextMenu {
    margin-top: 45px;

    a {
        text-decoration: none;
        color: #3e3e3e;
        font-size: 20px;
        font-weight: 600;
        border-left: 5px #116297 solid;
        padding-left: 25px;
    }
}