.overflowAnimate {
    overflow: hidden;

    .OAO {
        margin-left: -500px;
        transition: 1s;

        &#animate {
            margin-left: 0;
        }
    }
}

.arz711-info {
    overflow: hidden;

    p {
        margin-left: -500px !important;
        transition: 1.5s;

        &#animate {
            margin-left: 0 !important;
        }
    }
}

.arz711 {
    > p {
        opacity: 0;
        transition: 3s;

        &#animate {
            opacity: 1;
        }
    }
}

.skyCircle {
    img {
        margin-left: 0;
        transition: linear 10s;

        // &#animate {
        //     margin-left: -50%;
        // }

        // &#animate2 {
        //     margin-left: 0;
        // }
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.backgroundMap {
    // &.test:before {
    //     content: "";
    //     background: #ffffff;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 3;
    //     transition: opacity 3s;
    // }

    .whiteBox {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ffffff;
        z-index: 3;
        transition: 1s;

        &#animate {
            opacity: 1;
        }
    }
}

.showOpacityText {
    opacity: 0;
    transition: 2s;

    &#animate {
        opacity: 1;
    }
}

.infoWinnerData {
    p {
        margin-left: -500%;
        transition: 1.5s;
    }
}

.arzWinner {
    &#animate {
        .showOpacityText {
            transition-delay: 1s;
            opacity: 1;
        }

        .infoWinner:nth-child(2) {
            .infoWinnerData > p:nth-child(1) {
                margin-left: 0%;
            }

            .infoWinnerData > p:nth-child(2) {
                margin-left: 0%;
                transition-delay: .5s;
            }
        }

        .infoWinner:nth-child(3) {
            .infoWinnerData > p:nth-child(1) {
                margin-left: 0%;
                transition-delay: .7s;
            }

            .infoWinnerData > p:nth-child(2) {
                margin-left: 0%;
                transition-delay: 1.2s;
            }
        }

        .infoWinner:nth-child(4) {
            .infoWinnerData > p:nth-child(1) {
                margin-left: 0%;
                transition-delay: 1.5s;
            }

            .infoWinnerData > p:nth-child(2) {
                margin-left: 0%;
                transition-delay: 1.8s;
            }
        }
    }
}

.arzInformation {
    p {
        opacity: 0;
        transition: .5s;
    }

    &#animate {
        p:nth-child(1) {
            transition-delay: 1s;
            opacity: 1;
        }

        p:nth-child(2) {
            transition-delay: 1.5s;
            opacity: 1;
        }

        p:nth-child(3) {
            transition-delay: 2s;
            opacity: 1;
        }

        p:nth-child(4) {
            transition-delay: 2.5s;
            opacity: 1;
        }
    }
}

.docsElem {
    opacity: 1;
    transition: 1s;

    // &#animate {
    //     // opacity: 1;
    //     transform: scale(1.05);
    // }
    &:hover {
        transform: scale(1.075);
    }
}

.licFile {
    opacity: 1;
    transition-delay: 2s;
    transition: 2s;
}

.data > div {
    &[podMenu] {
        transition: 1s;
        overflow-x: hidden;
        // display: flex;
        // flex-direction: row;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .podMenu {
            // width: 100%;
        }

        .podMenu:not(:first-child) {
            margin-left: 130px;
            padding-right: 90px;
        }
    }

    &[podMenu="prof"] {
        margin-left: -100%;
    }
}