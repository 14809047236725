.mainMenu {
    position: relative;
    // z-index: 3;
    z-index: 15;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    margin-top: 75px;
    padding-right: 90px;

    > div {
        cursor: pointer;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 18px;
        color: #3e3e3e;

        .line {
            width: 0%;
            height: 3px;
            transition: 1s;
            border-radius: 5px;
            margin-top: 5px;
        }

        .line.active {
            width: 100%;
            background: #116297;
        }
    }

    > div:not(:first-child) {
        padding-left: 30px;
    }
}