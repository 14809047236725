.contentInfo {
    height: 100%;
    margin-top: -105px;
    overflow: hidden;
}

.contentData {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100%;
    // height: calc(100% - 50px);
}

.blockName {
    margin: 0;
    font-size: 32px;
    font-family: "Montserrat";
    font-weight: bold;
    color: #3e3e3e;
    padding-bottom: 35px;
}

.data {
    overflow: scroll;
    // height: calc(100vh - 200px);
    // height: calc(100% - 50px);
    // height: calc(100vh - 150px);
    overflow-x: hidden;

    height: calc(100vh - 186px);

    > div {
        padding: 25px;
        padding-right: 90px;
    }

    > div:first-child {
        // height: 
        // height: calc(100vh - 200px);
        // overflow: scroll;
        min-height: calc(100% - 85px);
    }

    .splitDate {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-family: "Montserrat";

        .splitDateNum {
            font-size: 30px;
            color: #3e3e3e;
            font-weight: 600;
            line-height: 30px;

            p:last-child {
                font-size: 23px;
                font-weight: 500;
            }
        }

        .splitDateStr {
            font-size: 16px;
            font-weight: 700;

            &:last-child {
                p {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    height: 100%;
                }
            }
        }

        > p {
            // font-size: 73px;
            font-size: 53px;
            line-height: 58px;
            font-weight: 700;
            color: #116297;
            padding: 0 10px;
        }

        p {
            margin: 0;
        }
    }
    
    .infoIntLines {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;

        .infoInt {
            > span {
                white-space: nowrap;
            }
        }
    }

    .arzContentMore {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-column-gap: 30px;

        padding-top: 15px;
    }

    .arzWinner {
        > p {
            font-size: 18px;
            padding-bottom: 25px;
        }
    }

    .arzInformation {
        font-family: "Montserrat";
        color: #3e3e3e;
    }

    .infoWinner {
        display: flex;
        flex-direction: row;
        padding-bottom: 25px;

        p {
            margin-top: 0;
            margin-bottom: 0;
        }

        > p {
            font-size: 70px;
            font-weight: 700;
            color: #116297;
            line-height: 55px;
        }

        .infoWinnerData {
            padding-left: 15px;
            margin-top: -5px;
            color: #3e3e3e;
            overflow: hidden;

            > p:first-child {
                font-weight: 700;
                font-size: 18px;
            }

            > p:last-child {
                font-size: 14px;
                width: 300px;
            }
        }
    }
}

.profUnion {
    // padding-top: 35px;

    .newsList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        .newsElem:first-child {
            grid-row-start: 1;
            grid-row-end: 3;
            height: 450px;
        }

        .newsElem {
            // height: 210px;
            padding: 15px;
            border-radius: 10px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: #ffffff;
            font-family: "Montserrat";
            position: relative;

            .newsName {
                margin: 0;
                font-size: 23px;
                font-weight: 700;
            }

            .newsText {
                font-size: 14px;
                margin: 0;
                margin-top: 5px;
            }

            > * {
                position: relative;
                z-index: 1;
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                // background: linear-gradient(45deg, #4f92b7, transparent);
                background: linear-gradient(90deg, #4f92b7 1%, transparent 99%);
                width: 100%;
                height: 100%;
                // opacity: 90%;
                opacity: 0.9;
                border-radius: 10px;
            }
        }
    }
}