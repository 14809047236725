.docsList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 20px;

    .docsElem {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: 5px;

        background: #f6f8fa;
        border-radius: 15px;
        border: 2px #e1ebf1 solid;

        align-items: center;

        cursor: pointer;

        img {
            width: calc(100% - 30px);
            margin: 10px 0;
            margin-left: 10px;
        }

        .docsInfo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
                font-size: 14px;
                word-break: break-word;
                font-weight: bold;
                margin: 0;
            }

            span {
                font-size: 12px;
            }
        }
    }
}

.docsYears {
    display: flex;
    font-size: 22px;
    margin-bottom: 25px;
    font-family: "Montserrat";

    p {
        margin: 0;
        font-weight: 600;
        color: #3f3f3f;
        cursor: pointer;
    }

    .active {
        color: #116297;
        font-weight: 700;
        border-bottom: 3px #116297 solid;
    }

    > p:not(:first-child) {
        margin-left: 15px;
    }
}

.docsYearsText {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    font-family: "Montserrat";
    color: #3f3f3f;
}

.licFile {
    background-size: cover !important;
    height: 200px;
    border-radius: 10px;
}

.licencesList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}

.licences {
    margin-top: 65px;
}