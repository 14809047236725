.gridContacts {
    display: grid;
    grid-template-columns: 2fr 3fr;
    font-family: "Montserrat";

    p {
        margin: 0;
    }
}

.contactHeadText {
    font-size: 21px;
    font-weight: 700;
    color: #116297;
    margin-bottom: 20px !important;
}

.contactRow {
    margin-bottom: 40px;

    > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 15px;
        font-size: 16px;

        > p:nth-child(2n - 1) {
            font-weight: bold;
        }
    }

    .contactMap {
        // height: 190px;
        display: block !important;
        margin-top: 15px;
        border: 1px #3e3e3e solid;
        // border-radius: 10px;

        & > div > ymaps:nth-child(1) {
            display: none;
        }
    }
}

.contactMapAdress {
    font-size: 14px;
    font-weight: bold;
}

.reqvContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;

    p:first-child {
        font-size: 12px;
        color: gray;
        margin: 0;
    }

    p:last-child {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
    }
}