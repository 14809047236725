@media screen and (max-width: 1000px) {
    .KTRVHeader {
        padding: 0 !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;

        > img {
            width: 50%;
        }

        > * {
            padding-bottom: 5px;
        }

        .KTRVmenu {
            a {
                font-size: 11px !important;
            }
        }
    }

    .OAOLine {
        font-size: 12px !important;
        text-align: center;
    }

    .menuVisual {
        position: fixed;
        top: 15px;
        left: 15px;
        width: 30px;
        height: 20px;
        z-index: 3;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > div {
            height: 3px;
            background: #3e3e3e;
            border-radius: 10px;
        }

        > div:nth-child(1) {
            width: 100%;
        }

        > div:nth-child(2) {
            width: 75%;
        }

        > div:nth-child(3) {
            width: 50%;
        }
    }

    .menuDisplay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 7;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > * {
            position: relative;
            z-index: 1;
            font-size: 25px;
            color: #f5f5f5;
            font-family: "Montserrat";
            font-weight: bold;
            // letter-spacing: 2px;
        }

        > *:not(:last-child) {
            margin-bottom: 25px;
        }

        &:after {
            content: "";
            background: #116297;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.85;
        }

        .mobileMenuExit {
            position: fixed;
            top: 15px;
            left: 15px;
            color: #f5f5f5;
            font-size: 55px;
            font-weight: 300;
            line-height: 15px;
        }
    }

    .bigKtrvLogo {
        height: unset !important;
        width: 100vw;
    }

    .contentHomepage {
        padding: 0 15px !important;
        // height: calc(100vh - 120px) !important;

        .OAO {
            font-size: 14px !important;
        }
    }

    .arz711 > p {
        font-size: 100px !important;
        line-height: 70px !important;
    }

    .arz711 .arz711-info {
        > p {
            font-size: 22px !important;
            line-height: 25px !important;
        }

        > p:not(:last-child) {
            padding: 0 !important;
        }
    }

    .skyCircle {
        height: 350px !important;
        width: 350px !important;
    }

    .skyBlock .clouds .cloud {
        height: 80px !important;
    }

    .skyContent {
        margin-right: -70px;
    }

    .backgroundMap {
        overflow-x: hidden;
    }






    .leftContent {
        // width: 35px !important;
        // padding: 0 !important;
        overflow: hidden;
        height: calc(100vh - 180px) !important;
    }

    .arzWinner {
        width: 100%;
        margin-right: -35px;
    }

    .openPodMenu {
        // transform: rotate(-90deg);
        // margin-top: 130px;
        height: 100%;
        opacity: 0;
        // transition: opacityAnim 2s;
        // transition-delay: 2s;
    }

    .content {
        margin-top: 100px;
    }

    .data > div {
        padding-right: 25px !important;
    }

    .closePodMenu {
        display: block !important;
    }

    



    .infoIntLines {
        grid-template-columns: 1fr !important;
        grid-column-gap: 0;
        grid-row-gap: 30px;
        justify-items: flex-start;
    }

    .arzContentMore {
        grid-template-columns: 1fr !important;
        grid-column-gap: 0;
    }

    .newsList {
        grid-template-columns: 1fr !important;
    }

    .profUnion .newsList .newsElem {
        height: 200px;
    }

    @keyframes opacityAnim {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .closePodMenu {
        font-size: 18px;
        font-weight: bold;
        color: #116297;
        margin-bottom: 55px;
    }

    .fixedPosition {
        opacity: 0;
    }

    .leftContent.active .fixedPosition {
        opacity: 1;
        transition: 1s;
        transition-delay: .3s;
    }


    .docsList {
        grid-template-columns: 1fr !important;
    }

    .licencesList {
        grid-template-columns: 1fr !important;
    }

    


    .gridContacts {
        grid-template-columns: 1fr !important;
    }

    .reqvContent {
        // grid-template-columns: 1fr 1fr !important;
        grid-template-columns: 1fr !important;
    }
}



@media screen and (max-width: 1200px) {
    .arz711 > p {
        font-size: 100px;
    }

    .arz711 .arz711-info > p {
        font-size: 22px;
    }

    .KTRVfooter {
        padding: 15px !important;
        flex-direction: column !important;
        text-align: center;

        & > div > img {
            margin-bottom: 2px !important;
        }

        & > div > * {
            margin-bottom: 0px !important;
        }

        .KTRVmenu {
            display: none !important;
        }
    }

    .KTRVHeader > img {
        width: 158px;
    }

    .docsYears {
        overflow: scroll;
        margin-right: -20px;
    }
}

@media screen and (min-width: 1300px) {
    .splitDate {
        > p > span {
            font-size: 73px !important;
        }
    }
}